<template>
  <div class="register absolute">
    <div class="shading">
      <div class="pictrue acea-row row-center-wrapper">
        <img src="@static/vip/newlogo.jpg" />
      </div>
    </div>
    <div class="whiteBg" v-if="formItem === 1">
      <div class="title acea-row row-center-wrapper">
        <div
          class="item"
          :class="current === index ? 'on' : ''"
          v-for="(item, index) in navList"
          @click="navTap(index)"
          :key="index"
        >{{ item }}</div>
      </div>
      <div class="list" :hidden="current !== 0">
        <form @submit.prevent="submit">
          <div class="item">
            <div class="acea-row row-between-wrapper">
              <span class="iconfont icongeren1"></span>
              <input type="text" placeholder="输入手机号码" v-model="account" required />
            </div>
          </div>
          <div class="item">
            <div class="acea-row row-between-wrapper">
              <div class="get-code">
                <span class="iconfont iconmima"></span>
                <!-- <label for="code">验证码</label> -->
                <input
                  id="code"
                  v-model="code"
                  type="text"
                  placeholder="请输入短信验证码"
                  style="width:91%"
                />
                <div class="code" @click="getCode">{{ code_text }}</div>
              </div>
              <!-- <span class="iconfont iconmima"></span>
              <input type="password" placeholder="填写登录密码" v-model="password" required /> -->
            </div>
          </div>
        </form>
      </div>
      <div class="logon" @click="userLogin" :hidden="current === 1">登录</div>
    </div>
    <div class="bottom"></div>
  </div>
</template>
<script>
import UserInfo from "@api/user";
export default {
  data: function() {
    return {
      code: "",
      code_text: "获取验证码",
      navList: ["账号登录"],
      current: 0,
      cipherText: "",
      account: "",
      password: "",
      captcha: "",
      formItem: 1,
      type: "login",
      btn_status: false,
      timer: null,
      count:0
    };
  },
  methods: {
    getCode() {
			const TIME_COUNT = 60;
			if (!this.btn_status) {
				if (!this.account) {
					this.$toast("手机号码不能为空");
				} else {
					if (!this.timer) {
						this.count = TIME_COUNT;
						UserInfo.getPhoneCode(this.account).then(res => {
							this.$toast(res.msg);
						});
						this.timer = setInterval(() => {
							if (this.count > 0 && this.count <= TIME_COUNT) {
								this.count--;
								this.code_text = this.count + "s";
								this.btn_status = true;
							} else {
								this.btn_status = false;
								this.timer = null;
								this.code_text = "获取验证码";
							}
						}, 1000);
					}
				}
			}
		},
    async userLogin() {
      try {
        if (this.account == "") return this.$toast("请输入手机号");
        if (this.code == "") return this.$toast("请输入验证码");
        const res = await UserInfo.codeLogin(this.account, this.code);
        if (res.code == 200) {
          localStorage.setItem("access_token", res.data.app_token)
          sessionStorage.setItem("access_token", res.data.app_token)
          this.$router.push("/user_promotion?appid=" + sessionStorage.getItem(
            "appid"
          ));
        } else {
          throw res.msg;
        }
      } catch(e) {
        this.$toast(e);
      }
    },
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
/*立即注册*/
.iconfont {
  font-size: 18px;
}
.get-code {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #dfdfdf;

  .code {
    width: 120px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
    color: #ff8000;
    position: absolute;
    right: 10px;
  }
}
.register {
  background-image: linear-gradient(to bottom, #f10c0c 0%, #ec9b08 100%);

  background-image: -webkit-linear-gradient(
    to bottom,
    #f10c0c 0%,
    #ec9b08 100%
  );

  background-image: -moz-linear-gradient(to bottom, #f10c0c 0%, #ec9b08 100%);
  width: 100%;
  height: 100vh;
}

.register .shading {
  background-image: url("../../../public/static/images/vip/registerw.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 143px;
  padding-top: 35px;
  display: flex;
  align-items: center;
}

.register .shading .pictrue {
  width: 136px;
  margin: 0 auto;
}

.register .shading .pictrue img {
  width: 100%;
  display: block;
}

.register .whiteBg {
  width: 310px;
  height: 252px;
  border-radius: 8px;
  background-color: #fff;
  margin: 15px auto 0 auto;
  padding: 22.5px 20px 25px 20px;
}

.register .whiteBg .title {
  font-size: 18px;
  color: #282828;
  text-align: center;
  font-weight: bold;
}

.register .whiteBg .title .item ~ .item {
  margin-left: 42.5px;
}

.register .whiteBg .title .item {
  color: #999999;
  border-bottom: 2px solid #fff;
  padding-bottom: 3px;
}

.register .whiteBg .title .item.on {
  color: #282828;
  border-bottom-color: #fff;
}

.register .whiteBg .list .item {
  border-bottom: 1px solid #ededed;
  padding: 23.5px 0 6.5px 0;
  position: relative;
}

.register .whiteBg .list .item .name {
  font-size: 13px;
  color: #2d3342;
  margin-bottom: 13px;
  text-align: left;
}

.register .whiteBg .list .item .icon {
  font-size: 17.5px;
  margin-right: 16px;
}

.register .whiteBg .list .item input {
  font-size: 16px;
  width: 245px;
  border: 0;
}

.register .whiteBg .list .item input::placeholder {
  color: #cccccc;
}

.register .whiteBg .list .item .codeIput {
  width: 125px;
}

.register .whiteBg .list .item .code {
  position: absolute;
  width: 75px;
  height: 25px;
  background-color: #f35446;

  border-radius: 15px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  bottom: 8.5px;
  right: 0;
  font-size: 12.5px;
}

.register .whiteBg .list .item .code.on {
  background-color: #bbbbbb;
}

.register .whiteBg .list .forgetPwd {
  text-align: right;
  font-size: 14px;
  color: #cccccc;
  margin-top: 10px;
}

.register .whiteBg .list .forgetPwd .iconfont {
  font-size: 15px;
  margin-right: 5px;
  vertical-align: middle;
}

.register .whiteBg .logon {
  font-size: 17px;
  color: #fff;
  font-weight: bold;
  height: 43px;
  border-radius: 21.5px;
  background: linear-gradient(to right, #f35447 0%, #ff8e3c 100%);

  background: -webkit-linear-gradient(to right, #f35447 0%, #ff8e3c 100%);

  background: -moz-linear-gradient(to right, #f35447 0%, #ff8e3c 100%);
  text-align: center;
  line-height: 43px;
  margin-top: 23.5px;
}

.register .whiteBg .tip {
  height: 55px;
  text-align: center;
  line-height: 52.5px;
  font-size: 15px;
  color: #cccccc;
}

.register .bottom {
  background-image: url("../../../public/static/images/vip/registerB.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 310px;
  height: 18px;
  margin: 0 auto;
}
</style>
